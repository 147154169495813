import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';

export function useToken() {
  const { user } = useAuth();
  const [token, setToken] = useState('');

  useEffect(() => {
    if (user) {
      user.getIdToken().then((receivedToken) => {
        setToken(receivedToken);
      });
    }
  }, [user]);

  return token;
}
