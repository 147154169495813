export enum SubscriptionIntervals {
  MONTHLY,
  YEARLY,
}

export type Interval = SubscriptionIntervals.MONTHLY | SubscriptionIntervals.YEARLY;

export enum LicenseKeyStatuses {
  UNCLAIMED = 'Unclaimed',
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

export type LicenseKeyStatus = LicenseKeyStatuses.ACTIVE | LicenseKeyStatuses.PENDING | LicenseKeyStatuses.UNCLAIMED;

export interface LicenseKey {
  licenseKey: string;
  userEmail?: string;
  name?: string;
  figmaId?: string;
  status: LicenseKeyStatus;
  id: string;
  expiry: Date;
  expiring: boolean;
  expired: boolean;
}

export interface SubScriptionDataType {
  currency: string;
  customer_name: string;
  amount_due: number;
  next_billing_month: string;
  next_billing_date: string;
  quantity: number;
  planName: string;
  interval: Interval;
  canceled: boolean;
  needsToAcceptTerms: boolean;
}

export interface Subscription {
  customerName: string;
  planName: string;
  seats: number;
  customerId: string;
  id: string;
  purchaseDate: string;
}
