import { useMemo } from 'react';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useParams } from 'react-router-dom';
import { SubScriptionDataType } from '../types';
import { useToken } from './useToken';

export function useSubScriptionData() {
  const params = useParams();
  const token = useToken();

  const { data, error, mutate } = useSWR<SubScriptionDataType>(
    token ? [`get-subscription-information?subscriptionId=${params.subscriptionId}`, token] : null,
  );
  const nextBilling = useMemo(() => {
    if (data?.next_billing_date) {
      const expiryDate = DateTime.fromISO(data.next_billing_date);
      const month = expiryDate.monthShort;
      const { day } = expiryDate;
      return {
        day,
        month,
      };
    }

    return undefined;
  }, [data?.next_billing_date]);

  const subscriptionData = useMemo(() => {
    if (data) {
      return {
        canceled: data.canceled,
        planName: data.planName,
        interval: data?.interval,
        nextBillingDate: `${nextBilling?.month ?? '-'} ${nextBilling?.day ?? '-'}`,
        seats: data.quantity,
        needsToAcceptTerms: data.needsToAcceptTerms,
        nextInvoicePrice: data.amount_due ? `${getSymbolFromCurrency(data.currency ?? '')} ${data.amount_due}` : '--',
      };
    }

    return null;
  }, [data, nextBilling?.day, nextBilling?.month]);

  return {
    subscriptionData,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
