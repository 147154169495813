import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { SWRConfig } from 'swr';
import * as Toast from '@radix-ui/react-toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/auth';
import { Box, ConfirmAlert, NotificationToast } from './shared/components';
import { fetcher } from './api/fetcher';
import { ConfirmContextProvider } from './context/ConfirmContext';
import { NotificationContextProvider } from './context/NotificationContext';
import { LoadingContextProvider } from './context/LoadingContext';
import { ErrorBoundary } from './components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Sentry.init({
  dsn: 'https://cdcc3240a1534c79a1571dc72c2de7a8@o386310.ingest.sentry.io/6635048',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
});

root.render(
  <Box
    css={{
      height: '100vh',
    }}
  >
    <ErrorBoundary>
      <React.StrictMode>
        <LoadingContextProvider>
          <AuthProvider>
            <SWRConfig
              value={{
                fetcher,
              }}
            >
              <NotificationContextProvider>
                <ConfirmContextProvider>
                  <Toast.Provider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                    <ConfirmAlert />
                    <NotificationToast />
                  </Toast.Provider>
                </ConfirmContextProvider>
              </NotificationContextProvider>
            </SWRConfig>
          </AuthProvider>
        </LoadingContextProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </Box>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
