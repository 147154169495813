import mixpanel, { Dict } from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? '');

const envCheck = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id: string) => {
    if (envCheck) {
      try {
        mixpanel.identify(id);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  },
  alias: (id: string) => {
    if (envCheck) {
      try {
        mixpanel.alias(id);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  },
  track: (name: string, props?: Dict) => {
    if (envCheck) {
      try {
        mixpanel.track(name, props);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  },
  people: {
    set: (props: Dict) => {
      if (envCheck) {
        try {
          mixpanel.people.set(props);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    },
  },
};

export const Mixpanel = actions;
