export default async function createPortalSession(
  customerId: string,
  returnUrl: string,
  token: string,
): Promise<string | null> {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/get-portal-link?customerId=${customerId}&returnUrl=${returnUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const response = await res.json();
    if (res.status === 200) {
      return response.url;
    }
    return null;
  } catch (e) {
    return null;
  }
}
