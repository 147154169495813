import { useState } from 'react';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const { addNotification } = useNotifications();

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      addNotification(NotificationTypes.ERORR, 'Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      addNotification(NotificationTypes.SUCCESS, 'License key was copied!');
      return true;
    } catch (error) {
      addNotification(NotificationTypes.ERORR, 'Copy failed');
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;
