import useSWR from 'swr';
import { Subscription } from '../types';
import { useToken } from './useToken';

export function useSubscriptions() {
  const token = useToken();
  const { data, error } = useSWR<Subscription[]>(token ? ['get-user-subscriptions', token] : null);

  return {
    subscriptions: data,
    isLoading: !error && !data,
    error,
  };
}
