import { styled } from '../../stitches.config';

const Button = styled('button', {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$medium',
  padding: '0 $5',
  fontSize: '$large',
  fontFamily: '$ibm',
  lineHeight: 1,
  fontWeight: 600,
  height: 32,
  cursor: 'pointer',
  '&:disabled': {
    opacity: 0.5,
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$bgButtonPrimary',
        color: '$fgDefault',
      },
      secondary: {
        backgroundColor: 'transparent',
        color: '$fgDefault',
        border: '1px solid $borderMuted',
      },
    },
  },

  defaultVariants: {
    variant: 'primary',
  },
});

export default Button;
