import { styled } from '../../stitches.config';

const Input = styled('input', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$small',
  padding: '0 $4',
  height: 32,
  fontSize: '$large',
  lineHeight: 1,
  width: '100%',
  variants: {
    variant: {
      white: {
        background: '$inputBg',
        border: '1px solid $inputBorder',
      },
      outline: {
        background: '$canvasSubtle',
        border: '1px solid $borderMuted',
        color: '$fgMuted',
      },
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export default Input;
