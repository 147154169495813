// stitches.config.ts
import { createStitches } from '@stitches/react';

export const stitchesInstance = createStitches({
  theme: {
    fonts: {
      ibm: 'IBM Plex Sans, sans-serif',
    },
    fontSizes: {
      xxsmall: '10px',
      xsmall: '11px',
      small: '12px',
      medium: '13px',
      large: '14px',
      xlarge: '16px',
      xxlarge: '18px',
      xxxlarge: '22px',
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    colors: {
      fgDefault: '#ECECEC',
      fgMuted: '#C5C5C5',
      fgSubtle: '#868686',
      canvasDefault: '#0A0D11',
      canvasInset: '#040609',
      canvasSubtle: '#131920',
      borderMuted: '#1C2633',
      bgButtonPrimary: '#55ADFF',
      alertError: '#633123',
      alertErrorBorder: '#633123',
      alertErrorText: '#F6C1B5',
      inputBg: '#F3F3F3',
      success: '#4B9E53',
      danger: '#FF3F3F',
      notificationSucces: '#7EDA97',
      notificationError: '#633123',
      alertErrorColor: '#F6C1B5',
      alertSuccessColor: '#1F823A',
    },
    space: {
      1: '2px',
      2: '4px',
      3: '8px',
      4: '12px',
      5: '16px',
      6: '24px',
      7: '32px',
      8: '48px',
      9: '64px',
      10: '38px',
    },
    sizes: {},
    radii: {
      small: '4px',
      medium: '6px',
    },
    shadows: {},
  },
});

const { styled, css, keyframes, theme } = stitchesInstance;

export { styled, css, keyframes, theme };
