import React from 'react';

function CopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <mask
        id="mask0_231_2747"
        style={{
          maskType: 'alpha',
        }}
        width="16"
        height="16"
        x="1"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10.333.667H3c-.733 0-1.333.6-1.333 1.333v8.667c0 .366.3.666.666.666.367 0 .667-.3.667-.666v-8C3 2.3 3.3 2 3.667 2h6.666C10.7 2 11 1.7 11 1.333c0-.366-.3-.666-.667-.666zM13 3.333H5.667c-.734 0-1.334.6-1.334 1.334V14c0 .733.6 1.333 1.333 1.333H13c.733 0 1.333-.6 1.333-1.333V4.667c0-.734-.6-1.334-1.333-1.334zM6.333 14h6c.367 0 .667-.3.667-.667v-8c0-.366-.3-.666-.667-.666h-6c-.367 0-.667.3-.667.666v8c0 .367.3.667.667.667z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_231_2747)">
        <path fill="currentColor" d="M0 0H16V16H0z" />
      </g>
    </svg>
  );
}

export default CopyIcon;
