import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import EmailSuccessMessage from './pages/EmailSuccessMessage';
import { Box } from './shared/components';
import { LoadingOverlay } from './components';
import SubscriptionSelection from './pages/SubscriptionSelection';

export default function App() {
  return (
    <Box
      css={{
        height: '100%',
      }}
    >
      <Routes>
        <Route path="/:customerId/:subscriptionId" element={<Dashboard />} />
        <Route path="/email-login" element={<Login />} />
        <Route path="/email-success/:email" element={<EmailSuccessMessage />} />
        <Route path="/choose-subscription" element={<SubscriptionSelection />} />
        <Route path="*" element={<Navigate to="/choose-subscription" replace />} />
      </Routes>
      <LoadingOverlay />
    </Box>
  );
}
