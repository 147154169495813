import React from 'react';
import { useLicenseKeys } from '../hooks/useLicenseKeys';
import { useSubScriptionData } from '../hooks/useSubscriptionData';
import { Box, Flex, InlineBox } from '../shared/components';
import InviteMember from './InviteMember';
import LicenseKeyList from './LicenseKeyList';

export default function DashboardContent() {
  const { licenseKeys } = useLicenseKeys();
  const { subscriptionData } = useSubScriptionData();
  if (!licenseKeys) {
    return null;
  }

  const subscriptionDataContent =
    subscriptionData && subscriptionData.seats === 1 ? (
      <InlineBox
        css={{
          fontWeight: '$semiBold',
          fontSize: '$xxxlarge',
          marginTop: '$5',
          alignItems: 'flex-end',
          color: '$fgDefault',
        }}
      >
        Your Subscription
      </InlineBox>
    ) : (
      <InviteMember />
    );

  return (
    <Flex
      css={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        css={{
          borderBottom: '1px solid $borderMuted',
          padding: '$7',
          width: '100%',
        }}
      >
        {subscriptionData && subscriptionDataContent}
      </Box>
      <Box
        css={{
          padding: '$7 $4',
          flexGrow: '1',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <LicenseKeyList licenseKeys={licenseKeys} />
      </Box>
    </Flex>
  );
}
