import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { Box, Button, Flex, Input, Spinner } from '../shared/components';
import { useLicenseKeys } from '../hooks/useLicenseKeys';
import { LicenseKey, LicenseKeyStatuses } from '../types';
import inviteMember from '../api/inviteMember';
import { useNotifications, NotificationTypes } from '../context/NotificationContext';
import { Mixpanel } from '../Mixpanel';
import { useAuth } from '../hooks/useAuth';

export default function InviteMember() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { addNotification } = useNotifications();
  const { mutate, licenseKeys } = useLicenseKeys();

  const onFormSubmit = async (values: { email: string }) => {
    const { email } = values;
    if (email) {
      try {
        const firstAvailableKey = licenseKeys?.find((license) => {
          const { figmaId, userEmail } = license;
          return !figmaId && !userEmail;
        });
        if (firstAvailableKey) {
          setLoading(true);
          // update on the server
          const token = await user?.getIdToken();
          if (token) {
            await inviteMember(email, firstAvailableKey.id, params.subscriptionId ?? '', token);
          }
          Mixpanel.track('Invited member');
          // update the local data immediately and revalidate (refetch)
          const newKey: LicenseKey = {
            ...firstAvailableKey,
            status: LicenseKeyStatuses.PENDING,
            userEmail: email,
          };
          const newKeys = licenseKeys?.map((license) => {
            if (license.id !== firstAvailableKey?.id) {
              return license;
            }

            return newKey;
          });
          mutate(newKeys);
          setLoading(false);
          addNotification(NotificationTypes.SUCCESS, `Succesfully invited ${email}`);
        } else {
          addNotification(
            NotificationTypes.ERORR,
            'No license key available. Please add more seats to the subscription first',
          );
        }
      } catch (error) {
        setLoading(false);
        addNotification(NotificationTypes.ERORR, `Error inviting ${email}. Please try again`);
        Sentry.captureException(error);
      }
    }
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box
              css={{
                fontWeight: '$semiBold',
                fontSize: '$xxxlarge',
                marginBottom: '$6',
                color: '$fgDefault',
              }}
            >
              Team members
            </Box>
            <Box
              css={{
                fontWeight: '$semiBold',
                fontSize: '$xlarge',
                marginBottom: '$3',
                color: '$fgDefault',
              }}
            >
              Add a new member
            </Box>
            <Box
              css={{
                fontSize: '$large',
                color: '$fgMuted',
                marginBottom: '$6',
              }}
            >
              Invite new team members by using their email
            </Box>
            <Flex
              css={{
                width: '100%',
                maxWidth: '470px',
                justifyContent: 'space-between',
              }}
            >
              <Field name="email">
                {({ input }) => (
                  <Input
                    css={{
                      flexGrow: '1',
                      marginRight: '$3',
                      maxWidth: '390px',
                    }}
                    variant="outline"
                    placeholder="john@doe.com"
                    type="email"
                    {...input}
                  />
                )}
              </Field>
              <Button
                css={{
                  flexGrow: '1',
                }}
                type="submit"
              >
                {loading ? <Spinner thickness="1" size="1" color="white" data-testid="invite-spinner" /> : 'Invite'}
              </Button>
            </Flex>
          </Box>
        </form>
      )}
    />
  );
}
