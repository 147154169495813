export default async function revokeLicense(licenseId: string, subscriptionId: string, token: string) {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/revoke-license`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify({
      licenseId,
      subscriptionId,
    }),
  });
  if (res.status === 200) {
    return true;
  }

  throw new Error('Unable to revoke license');
}
