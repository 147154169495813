import React, { createContext, useEffect, useState } from 'react';
import { FirebaseError } from '@firebase/util';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential,
  sendSignInLinkToEmail,
  getAuth,
} from 'firebase/auth';
import * as Sentry from '@sentry/react';
import validateEmail from '../api/validateEmail';
import { auth } from '../api/firebase';
import { useGlobalLoading } from './LoadingContext';
import { Mixpanel } from '../Mixpanel';

const allProviders = [
  {
    id: 'password',
    name: 'password',
  },
  {
    id: 'google.com',
    name: 'google',
    providerMethod: new GoogleAuthProvider(),
  },
];

const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';

export enum AuthProviderType {
  password = 'password',
  google = 'google',
}

export type AuthContextData = {
  authError: string;
  user: User | null;
  signUp: (email: string, password: string, name?: string) => Promise<User>;
  signIn: (email: string, password: string) => Promise<User>;
  trySignIn: () => void;
  signInWithLink: (email: string) => Promise<boolean>;
  signInWithProvider: (provider: AuthProviderType) => Promise<User> | undefined;
  signOut: () => Promise<void>;
  setAuthError: (errorMessage: string) => void;
  tryingLogin: boolean;
  showTermsAndConditions: boolean;
  setShowTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = createContext({});

function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState<User | null>(null);
  const [tryingLogin, setTryingLogin] = useState(true);
  const [authError, setAuthError] = useState('');
  const { setIsLoading } = useGlobalLoading();

  // Handle response from authentication functions
  const handleAuth = async (response: UserCredential) => {
    const { user: userData } = response;
    Mixpanel.identify(userData.uid);
    Mixpanel.people.set({
      $email: userData.email ?? '',
    });

    setUser(userData);
    return userData;
  };

  const signUp: AuthContextData['signUp'] = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return handleAuth(userCredential);
  };

  const signIn: AuthContextData['signIn'] = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return handleAuth(userCredential);
  };

  const signInWithLink: AuthContextData['signInWithLink'] = async (email: string): Promise<boolean> => {
    try {
      const emailIsValid = await validateEmail(email);
      if (emailIsValid) {
        const authApp = getAuth();
        await sendSignInLinkToEmail(authApp, email, {
          url: `${protocol}://${window.location.host}/email-login`,
          handleCodeInApp: true,
        });
        window.localStorage.setItem('emailForSignIn', email);
        if (authError) {
          setAuthError('');
        }
        return true;
      }
      setAuthError('Invalid email. Please enter the email that was used to purchase a Tokens Studio subscription');
      return false;
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/invalid-email':
            setAuthError('Invalid email. Please make sure the entered email is valid');
            break;
          default:
            setAuthError('Something went wrong. Please try again');
            break;
        }
      } else {
        setAuthError('Something went wrong. Please try again');
      }

      Sentry.captureException(error);
      return false;
    }
  };

  const trySignIn: AuthContextData['trySignIn'] = async () => {
    setIsLoading(true);
    const authApp = getAuth();
    if (isSignInWithEmailLink(authApp, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }

      if (email) {
        const result = await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        handleAuth(result);
      }
    } else {
      setIsLoading(false);
    }
  };

  const signInWithProvider: AuthContextData['signInWithProvider'] = (name) => {
    const providerData = allProviders.find((p) => p.name === name);

    if (providerData && providerData.providerMethod) {
      return signInWithPopup(auth, providerData.providerMethod).then(handleAuth);
    }

    return undefined;
  };

  const signOut: AuthContextData['signOut'] = () => auth.signOut();

  useEffect(() => {
    setTryingLogin(true);

    const unsubscribe = auth.onAuthStateChanged(async (userData) => {
      if (userData) {
        setUser(userData);
        Mixpanel.identify(userData.uid);
        Mixpanel.people.set({
          $email: userData.email ?? '',
        });
      } else {
        setUser(null);
      }
      setTryingLogin(false);
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    signUp,
    signIn,
    signInWithProvider,
    signOut,
    signInWithLink,
    trySignIn,
    authError,
    setAuthError,
    tryingLogin,
  } as AuthContextData;
}

export function AuthProvider({ children }: { children: JSX.Element }) {
  const authApp = useAuthProvider();

  return <AuthContext.Provider value={authApp}>{children}</AuthContext.Provider>;
}
