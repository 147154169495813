import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Box, Flex } from '../shared/components';
import Logo from '../shared/svgs/Logo';
import { LoginForm } from '../components';
import { useGlobalLoading } from '../context/LoadingContext';
import { useAuth } from '../hooks/useAuth';

export default function Login() {
  const { signInWithLink, trySignIn, user, authError } = useAuth();
  const { isLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    trySignIn();
  }, [trySignIn, isLoading]);

  const onLoginSubmit = async (values: { email: string }) => {
    setLoading(true);
    const { email } = values;
    if (email) {
      const emailsent = await signInWithLink(email);
      if (emailsent) {
        navigate(`/email-success/${email}`);
      }
    }
    setLoading(false);
  };

  const error = authError && (
    <Box
      css={{
        marginBottom: '$5',
        textAlign: 'center',
        background: '$alertError',
        border: '1px solid $alertErrorBorder',
        fontSize: '$large',
        borderRadius: '$medium',
        color: '$alertErrorText',
        padding: '$5',
      }}
    >
      {authError}
    </Box>
  );

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$canvasDefault',
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
          maxWidth: '320px',
          width: '100%',
          '& svg': {
            color: '$fgDefault',
            fill: '$fgDefault',
          },
        }}
      >
        <Box
          css={{
            marginBottom: '$7',
          }}
        >
          <Logo />
        </Box>
        {error}
        <Form
          onSubmit={onLoginSubmit}
          render={({ handleSubmit }) => <LoginForm onSubmit={handleSubmit} loading={loading} />}
        />
      </Flex>
    </Flex>
  );
}
