import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { LicenseKey } from '../types';
import { useToken } from './useToken';

export function useLicenseKeys() {
  const params = useParams();
  const token = useToken();

  const { data, error, mutate } = useSWR<LicenseKey[]>(
    token ? [`get-license-keys?subscriptionId=${params.subscriptionId}`, token] : null,
  );

  return {
    licenseKeys: data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
