import { Field } from 'react-final-form';
import { Box, Button, Flex, Input, Label, Spinner } from '../shared/components';

interface LoginFormProps {
  onSubmit: () => void;
  loading: boolean;
}

export default function LoginForm({ onSubmit, loading }: LoginFormProps): JSX.Element {
  return (
    <form
      onSubmit={onSubmit}
      style={{
        width: '100%',
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          css={{
            marginBottom: '$5',
            width: '100%',
          }}
        >
          <Field name="email">
            {(inputProps) => (
              <>
                <Box
                  css={{
                    marginBottom: '$3',
                  }}
                >
                  <Label htmlFor="email-input">E-mail</Label>
                </Box>
                <Input placeholder="Enter E-mail" type="email" {...inputProps.input} id="email-input" />
              </>
            )}
          </Field>
        </Box>
        <Button
          type="submit"
          css={{
            display: 'flex',
            width: '100%',
          }}
        >
          {loading ? <Spinner data-testid="login-spinner" size="1" thickness="1" color="white" /> : 'Submit'}
        </Button>
      </Flex>
    </form>
  );
}
