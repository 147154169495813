import { Flex, Box, Button } from '../shared/components';

export default function ErrorFallback() {
  return (
    <Flex
      css={{
        marginBottom: '$5',
        textAlign: 'center',
        fontSize: '$large',
        borderRadius: '$medium',
        padding: '$5',
        border: '1px dashed $borderMuted',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        background: '$canvasDefault',
        color: '$fgDefault',
      }}
    >
      <Box
        css={{
          marginBottom: '$6',
          fontSize: '$xlarge',
        }}
      >
        Oops! Something went wrong. Our team has been notified.
      </Box>
      <Button
        variant="secondary"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload the page
      </Button>
    </Flex>
  );
}
