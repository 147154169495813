import React from 'react';
import { useGlobalLoading } from '../context/LoadingContext';
import { Flex, InlineBox } from '../shared/components';
import Logo from '../shared/svgs/Logo';
import { keyframes } from '../stitches.config';

const dots = keyframes({
  '0%, 20%': {
    content: '.',
  },
  '40%': {
    content: '..',
  },
  '60%': {
    content: '...',
  },
  '90, 100%': {
    content: '',
  },
});

export default function LoadingOverlay() {
  const { isLoading } = useGlobalLoading();
  return isLoading ? (
    <Flex
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100%',
        background: '$canvasDefault',
        color: '$fgDefault',
        justifyContent: 'flex-start',
      }}
    >
      <Flex
        css={{
          width: '100%',
          flexDirection: 'column',
          '& svg': {
            color: '$fgDefault',
            fill: '$fgDefault',
          },
        }}
      >
        <Logo />
        <Flex
          css={{
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            width: '100px',
            paddingLeft: '$6',
          }}
        >
          <InlineBox
            css={{
              marginTop: '$5',
              color: '$fgMuted',
              fontSize: '$large',
            }}
          >
            Loading
          </InlineBox>
          <InlineBox
            css={{
              '&:after': {
                animation: `${dots} 2s linear infinite`,
                content: '',
              },
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}
