export default async function acceptTerms(subscriptionId: string, token: string) {
  await fetch(`${process.env.REACT_APP_SERVER_URL}/accept-terms`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify({
      subscriptionId,
    }),
  });
}
