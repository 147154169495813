import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '../shared/components';
import { DashboardContent, ErrorBoundary, Sidebar } from '../components';
import { useGlobalLoading } from '../context/LoadingContext';
import { useLicenseKeys } from '../hooks/useLicenseKeys';
import { useSubScriptionData } from '../hooks/useSubscriptionData';
import { useAuth } from '../hooks/useAuth';
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup';

export default function Dashboard() {
  const navigate = useNavigate();
  const { user, tryingLogin } = useAuth();
  const { isLoading: loadingLicenseKeys } = useLicenseKeys();
  const { isLoading: loadingSubscriptionData } = useSubScriptionData();
  const { setIsLoading } = useGlobalLoading();

  useEffect(() => {
    setIsLoading(loadingSubscriptionData || loadingLicenseKeys);
  }, [loadingLicenseKeys, loadingSubscriptionData, setIsLoading]);

  useEffect(() => {
    if (!user && !tryingLogin) {
      navigate('/email-login');
    }
  }, [user, navigate, tryingLogin]);

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$canvasDefault',
        color: '$fgDefault',
        justifyContent: 'flex-start',
        minWidth: 'min-content',
      }}
    >
      <Box
        css={{
          maxWidth: '209px',
          height: '100%',
        }}
      >
        <ErrorBoundary>
          <Sidebar />
        </ErrorBoundary>
      </Box>
      <ErrorBoundary>
        <DashboardContent />
      </ErrorBoundary>
      <TermsAndConditionsPopup />
    </Flex>
  );
}
