import { Cross1Icon } from '@radix-ui/react-icons';
import { DateTime } from 'luxon';
import React from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import acceptTerms from '../api/acceptTermsAndConditions';
import { useAuth } from '../hooks/useAuth';

import { useSubScriptionData } from '../hooks/useSubscriptionData';
import { Button, Flex } from '../shared/components';
import { SubScriptionDataType } from '../types';

const launchDate = process.env.REACT_APP_LATEST_TERMS_LAUNCH_DATE ?? '';

export default function TermsAndConditionsPopup() {
  const { user } = useAuth();
  const { subscriptionData, mutate } = useSubScriptionData();
  const params = useParams();

  if (!subscriptionData?.needsToAcceptTerms) {
    return null;
  }

  const onClose = async () => {
    if (params.subscriptionId) {
      const token = await user?.getIdToken();
      if (token) {
        await acceptTerms(params.subscriptionId, token);
        mutate({ ...subscriptionData, needsToAcceptTerms: false } as unknown as SubScriptionDataType);
      }
    }
  };

  const readableDate = DateTime.fromMillis(+launchDate)
    .toUTC()
    .toLocaleString();

  return createPortal(
    <Flex
      css={{
        position: 'fixed',
        top: 0,
        width: '100%',
        padding: '$5',
        gap: '$10',
        background: '$canvasDefault',
        borderBottom: '1px solid $borderMuted',
      }}
    >
      <a
        href="https://tokens.studio/terms"
        style={{ color: '#fff' }}
        target="_blank"
        rel="noreferrer"
        onClick={onClose}
      >
        {`We've updated our General Terms & Conditions at ${readableDate}. Click here to see the updated version.`}
      </a>
      <Button variant="secondary" css={{ padding: '$3' }} onClick={onClose}>
        <Cross1Icon />
      </Button>
    </Flex>,
    document.body,
  );
}
