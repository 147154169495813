import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '../components/Logout';
import { useGlobalLoading } from '../context/LoadingContext';
import { useAuth } from '../hooks/useAuth';
import { useSubscriptions } from '../hooks/useSubscriptions';
import { Box, Button, Flex } from '../shared/components';
import Logo from '../shared/svgs/Logo';

export default function SubscriptionSelection() {
  const { subscriptions, isLoading } = useSubscriptions();
  const navigate = useNavigate();
  const { setIsLoading } = useGlobalLoading();
  const { user, tryingLogin } = useAuth();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (subscriptions?.length === 1) {
      // skip the choose sub screen if theres only one subscription
      navigate(`/${subscriptions[0].customerId}/${subscriptions[0].id}`);
    }
  }, [navigate, subscriptions]);

  useEffect(() => {
    if (!user && !tryingLogin) {
      navigate('/email-login');
    }
  }, [user, navigate, tryingLogin]);

  if (!subscriptions) {
    return null;
  }

  return (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        background: '$canvasDefault',
        color: '$fgDefault',
        padding: '0 $6',
      }}
    >
      <Flex
        css={{
          width: '100%',
          maxWidth: '506px',
          flexDirection: 'column',
        }}
      >
        <Logo />
        <Box
          css={{
            width: '100%',
            marginTop: '$5',
          }}
        >
          {subscriptions.map(({ id, planName, seats, customerName, customerId, purchaseDate }) => (
            <Flex
              key={id}
              css={{
                border: '1px solid $borderMuted',
                width: '100%',
                justifyContent: 'space-between',
                padding: '$3 $5',
                marginBottom: '$5',
                borderRadius: '$medium',
              }}
            >
              <Box>
                <Box
                  css={{
                    fontSize: '$xxlarge',
                    color: '$fgMuted',
                    fontWeight: '$semiBold',
                  }}
                >
                  {customerName}
                </Box>
                <Box
                  css={{
                    fontSize: '$large',
                    color: '$fgSubtle',
                    fontWeight: '$medium',
                  }}
                >
                  {`${planName} ${seats > 1 ? `, ${seats} seats` : ''}`}
                </Box>
                <Box
                  css={{
                    fontSize: '$large',
                    color: '$fgSubtle',
                    fontWeight: '$medium',
                  }}
                >
                  {`Purchased: ${purchaseDate}`}
                </Box>
              </Box>

              <Link to={`/${customerId}/${id}`}>
                <Button variant="secondary">Login</Button>
              </Link>
            </Flex>
          ))}
        </Box>
        <Logout />
      </Flex>
    </Flex>
  );
}
