interface ValidateResponse {
  isValid: boolean;
}

export default async function validateEmail(email: string) {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/validate-email?email=${encodeURIComponent(email)}`);
  if (res.status === 200) {
    const { isValid } = (await res.json()) as ValidateResponse;
    return isValid;
  }
  throw await res.json();
}
