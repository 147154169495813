import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { InlineBox } from '../shared/components';

export default function Logout() {
  const { signOut } = useAuth();

  return (
    <InlineBox
      css={{
        fontSize: '$large',
        textDecoration: 'underline',
        color: '$fgDefault',
        cursor: 'pointer',
      }}
      onClick={signOut}
    >
      Sign out
    </InlineBox>
  );
}
