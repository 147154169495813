import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setIsLoading: () => {},
});

function LoadingContextProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);

  const providerValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading],
  );

  return <LoadingContext.Provider value={providerValue}>{children}</LoadingContext.Provider>;
}

function useGlobalLoading() {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useGlobalLoading must be used within a LoadingContextProvider');
  }
  return context;
}

export { LoadingContextProvider, useGlobalLoading };
