export const fetcher = async (url: string, token: string) =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/${url}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  }).then(async (res) => {
    if (res.status === 200) {
      return res.json();
    }

    throw await res.json();
  });
