import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import revokeLicense from '../api/revokeLicense';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import useConfirmModal from '../hooks/useConfirmModal';
import { useLicenseKeys } from '../hooks/useLicenseKeys';
import { Box, Button, Flex, InlineBox } from '../shared/components';
import CopyIcon from '../shared/svgs/Copy';
import { LicenseKeyStatus, LicenseKeyStatuses } from '../types';
import { Mixpanel } from '../Mixpanel';
import { useAuth } from '../hooks/useAuth';

interface LicenseKeyItemProps {
  licenseKey: string;
  index: number;
  status: LicenseKeyStatus;
  name?: string;
  userEmail?: string;
  figmaId?: string;
  id: string;
  copiedValue: string | null;
  copyToClipboard: (key: string) => void;
  expiry: Date;
  expiring: boolean;
  expired: boolean;
}

export default function LicenseKeyItem({
  licenseKey,
  status,
  index,
  name,
  userEmail,
  figmaId,
  id,
  copiedValue,
  copyToClipboard,
  expiry,
  expired,
  expiring,
}: LicenseKeyItemProps) {
  const { user } = useAuth();
  const [removingLicense, setRemovingLicense] = useState(false);
  const { mutate, licenseKeys } = useLicenseKeys();
  const { addNotification } = useNotifications();
  const params = useParams();
  const { confirm } = useConfirmModal();
  const keyWasCopied = copiedValue === licenseKey;

  const revoke = async () => {
    let title = 'Revoke access';
    let description = `Are you sure you want to revoke access to Tokens Studio for ${
      name || userEmail || figmaId
    }? A new license key will replace this one`;
    let confirmButtonText = 'Revoke access';
    let successMessage = 'Succesfully revoked license. A new license key was generated';

    if (expired || (expiring && !figmaId)) {
      title = 'Remove license key';
      description = 'Are you sure you want to remove this license key?';
      confirmButtonText = 'Remove key';
      successMessage = 'Succesfully removed license key';
    }

    const userConfirmation = await confirm({
      title,
      description,
      cancelButtonText: 'Cancel',
      confirmButtonText,
      visible: true,
    });

    if (userConfirmation) {
      try {
        setRemovingLicense(true);

        const token = await user?.getIdToken();
        if (token) {
          await revokeLicense(id, params.subscriptionId ?? '', token);

          Mixpanel.track('Revoked license', {
            status,
          });
        }
        const newKeys = licenseKeys?.filter((license) => license.id !== id);
        mutate(newKeys);
        addNotification(NotificationTypes.SUCCESS, successMessage);
      } catch (error) {
        addNotification(NotificationTypes.ERORR, 'Error revoking license');
        Sentry.captureException(error);
      }
    }
    setRemovingLicense(false);
  };

  const getLicenseIdInformation = () => {
    if (status === LicenseKeyStatuses.UNCLAIMED) {
      return 'Untaken key';
    }
    return name || userEmail;
  };

  const getStatus = () => {
    const mainStatus = status === LicenseKeyStatuses.PENDING && (
      <Box
        css={{
          marginRight: '$5',
          fontSize: '$xlarge',
          color: '$fgMuted',
        }}
      >
        {status}
      </Box>
    );

    let secondaryStatus;
    if (expiring) {
      const expiryDate = new Date(expiry);
      const month = expiryDate.getMonth() + 1;
      const day = expiryDate.getDate();

      secondaryStatus = (
        <Box
          css={{
            marginRight: '$5',
            fontSize: '$small',
            fontWeight: '$bold',
            color: '$danger',
          }}
        >
          {`Expires on ${month}/${day}`}
        </Box>
      );
    }

    if (expired) {
      secondaryStatus = (
        <Box
          css={{
            marginRight: '$5',
            fontSize: '$small',
            fontWeight: '$bold',
            color: '$danger',
          }}
        >
          Expired
        </Box>
      );
    }

    return (
      <Box
        css={{
          textAlign: 'right',
        }}
      >
        {mainStatus}
        {secondaryStatus}
      </Box>
    );
  };

  return (
    <Box
      key={licenseKey}
      data-testid={`license-key-item-${id}`}
      css={{
        color: '$fgDefault',
        borderTop: index === 0 ? 'none' : '1px solid $borderMuted',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: '$5',
        opacity: removingLicense ? '0.5' : 1,
      }}
    >
      <Flex
        css={{
          justifyContent: 'flex-start',
          fontSize: '$xlarge',
          color: '$fgDefault',
        }}
      >
        {getLicenseIdInformation()}
      </Flex>
      <Flex
        css={{
          fontSize: '$small',
          fontWeight: '$light',
          margin: '0 $5',
          border: `1px solid ${keyWasCopied ? '$success' : 'transparent'}`,
          padding: '$2',
        }}
        data-testid={`license-key-container-${id}`}
      >
        <InlineBox
          css={{
            marginRight: '$3',
            color: '$fgMuted',
          }}
        >
          {licenseKey}
        </InlineBox>
        <Box
          css={{
            cursor: 'pointer',
            '& svg': {
              color: '$fgSubtle',
            },
          }}
          onClick={() => {
            copyToClipboard(licenseKey);
          }}
          data-testid={`copy-license-${id}`}
        >
          <CopyIcon />
        </Box>
      </Flex>
      <Flex
        css={{
          justifyContent: 'flex-end',
        }}
      >
        {getStatus()}
        <Button
          data-testid={`revoke-${id}`}
          variant="secondary"
          onClick={revoke}
          disabled={status === LicenseKeyStatuses.UNCLAIMED}
        >
          Revoke
        </Button>
      </Flex>
    </Box>
  );
}
