import React from 'react';

function Info() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <mask
        id="mask0_293_18532"
        style={{
          maskType: 'alpha',
        }}
        width="20"
        height="20"
        x="2"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 5v2h2V7h-2zm2 9c0 .55-.45 1-1 1s-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4zm-9-4c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_293_18532)">
        <path fill="inherit" d="M0 0H24V24H0z" />
      </g>
    </svg>
  );
}

export default Info;
