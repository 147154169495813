import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import React from 'react';
import * as Sentry from '@sentry/react';
import ErrorFallback from './ErrorFallback';

export default function ErrorBoundary({ children }: { children: JSX.Element }) {
  const onError = (error: Error) => {
    Sentry.captureException(error);
  };
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
}
